// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import Workbench from '@/views/workbench/index.vue';
import ClockingRecord from '@/views/clocking-record/index.vue'; // 假设你有一个 About 组件

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'workbench',
    component: Workbench
  },
  {
    path: '/clockingRecord',
    name: 'ClockingRecord',
    component: ClockingRecord
  }
];

const router = new Router({
  mode: 'history', // 使用 history 模式
  base: process.env.BASE_URL,
  routes
});

export default router;