<template>
  <div id="app">
    <el-container>
      <el-aside width="200px" class="menu-wrapper">
        <div v-for="(item, i) in menuList" :key="i">
          <router-link class="menu-list" active-class="active-menu"
           tag="div" :to="{ path: item.path, query: $route.query }">
           {{ item.name }}
          </router-link>
        </div>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      menuList: [
        {name: '工作台', path: '/'},
        {name: '打卡记录', path: '/clockingRecord'},
      ]
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

html,body,#app {
  height: 100vh;
  margin: 0;
  padding: 0;
}

/* .menu-wrapper{
  padding: 20px;
  background-color: #f5f5f7;
}

.menu-list{
  padding: 10px 15px;
  margin: 10px 0;
  background: #fff;
  border-radius: 10px;
}

.menu-list:hover{
  background: #ddeeff;
  cursor: pointer;
}

.menu-wrapper .router-link-exact-active{
  background-color: #99cbff;
} */

.el-container{
  height: 100%;
}

/* 侧边栏容器 */
.menu-wrapper {
  background-color: #f5f5f7;
  color: #333333;
  overflow-y: auto;
  transition: all 0.3s ease;
}

/* 侧边栏项容器 */
.menu-list {
  padding: 12px 16px;
  color: #333333;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
}

/* 悬停效果 */
.menu-list:hover {
  background-color: #e0e0e0;
  color: #212121;
}

/* 激活状态样式 */
.menu-list.router-link-exact-active {
  background-color: #d4e6f1;
  color: #212121;
  font-weight: bold;
}

/* 激活状态下的图标 */
.menu-list.router-link-exact-active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: #2980b9;
}
</style>
