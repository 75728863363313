<template>
    <div class="clocking-record">
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
        <el-form-item label="车牌号" prop="carPlate">
          <el-input
            v-model="queryParams.carPlate"
            placeholder="请输入车牌号"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="车队名称" prop="teamName">
          <el-input
            v-model="queryParams.teamName"
            placeholder="请输入车队名称"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="线路名称" prop="lineName">
          <el-input
            v-model="queryParams.lineName"
            placeholder="请输入线路名称"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker
            v-model="daterangeBeginTime"
            style="width: 240px"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select v-model="queryParams.state" placeholder="请选择运输状态" filterable clearable>
            <el-option
              v-for="(item,index) in stateList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-model="queryParams.phone"
            placeholder="请输入联系人电话"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="联系人" prop="driverName">
          <el-input
            v-model="queryParams.driverName"
            placeholder="请输入联系人"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
  
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleEnd"
          >结束</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
          >删除</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="warning"
            plain
            icon="el-icon-download"
            size="mini"
            @click="handleExport"
          >导出</el-button>
        </el-col>
        <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
      </el-row>
  
      <el-table stripe height="100%" v-loading="loading" :data="worksheetList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column label="ID" align="center" prop="id"/>
        <el-table-column label="车牌号" align="center" prop="carPlate" />
        <el-table-column label="车队名称" align="center" prop="teamName" />
        <el-table-column label="线路名称" align="center" prop="lineName" />
        <el-table-column label="打卡模式" align="center" prop="mode" />
        <el-table-column label="开始时间" align="center" prop="beginTime" width="180">
        </el-table-column>
        <el-table-column label="结束时间" align="center" prop="endTime" width="180">
        </el-table-column>
        <el-table-column label="状态" align="center" prop="state" >
            <template slot-scope="scope">
                {{ scope.row.state === '0'?'开始中':'结束' }}
            </template>
        </el-table-column>
        <el-table-column label="联系人电话" align="center" prop="phone" />
        <el-table-column label="联系人" align="center" prop="driverName" />
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleEnd(scope.row)"
            >结束</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
  
      <!-- 添加或修改运输监管工作单对话框 -->
    </div>
  </template>
  
  <script>
  import { listWorksheet, delWorksheet, endWorksheet } from "@/api/clocking-record";
  
  export default {
    name: "Worksheet",
    data() {
      return {
        // 按钮loading
        buttonLoading: false,
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 显示搜索条件
        showSearch: true,
        // 总条数
        total: 0,
        // 运输监管工作单表格数据
        worksheetList: [],
        // 联系人时间范围
        daterangeBeginTime: [],
        // 联系人时间范围
        daterangeEndTime: [],
  
        stateList: [
          { id: 0, label: '拉运中', value: '0' },
          { id: 1, label: '已结束', value: '1' }
        ],
  
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 20,
          carPlate: undefined,
          teamName: undefined,
          lineName: undefined,
          mode: undefined,
          beginTime: undefined,
          state: undefined,
          phone: undefined,
          driverName: undefined
        },
      };
    },
    created() {
      this.getList();
    },
    methods: {
      /** 查询运输监管工作单列表 */
      getList() {
        this.loading = true;
        this.queryParams.params = {};
        if (null != this.daterangeBeginTime && '' != this.daterangeBeginTime) {
          this.queryParams.params["beginBeginTime"] = this.daterangeBeginTime[0];
          this.queryParams.params["endBeginTime"] = this.daterangeBeginTime[1];
        }
        if (null != this.daterangeEndTime && '' != this.daterangeEndTime) {
          this.queryParams.params["beginEndTime"] = this.daterangeEndTime[0];
          this.queryParams.params["endEndTime"] = this.daterangeEndTime[1];
        }
        listWorksheet(this.queryParams).then(response => {
          this.worksheetList = response.rows;
          this.total = response.total;
        }).finally(() => {
          this.loading = false;
        })
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.daterangeBeginTime = [];
        this.daterangeEndTime = [];
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length!==1
        this.multiple = !selection.length
      },
      /** 结束按钮操作 */
      handleEnd(row) {
        const ids = row.id || this.ids;
        this.$modal.confirm('是否确认结束运输监管工作单编号为"' + ids + '"的数据项？').then(() => {
          this.loading = true;
          console.log(ids);
          return endWorksheet(ids);
        }).then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess("结束成功");
        }).catch(() => {
        }).finally(() => {
          this.loading = false;
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const ids = row.id || this.ids;
        this.$modal.confirm('是否确认删除运输监管工作单编号为"' + ids + '"的数据项？').then(() => {
          this.loading = true;
          return delWorksheet(ids);
        }).then(() => {
          this.loading = false;
          this.getList();
          this.$modal.msgSuccess("删除成功");
        }).catch(() => {
        }).finally(() => {
          this.loading = false;
        });
      },
      /** 导出按钮操作 */
      handleExport() {
        this.download('system/worksheet/export', {
          ...this.queryParams
        }, `worksheet_${new Date().getTime()}.xlsx`)
      }
    }
  };
</script>

<style>
.clocking-record{
    display: flex;
    flex-direction: column;
    height: 100%;
}
</style>