import Vue from 'vue'
import App from './App.vue'

// 监听元素大小变化(为echarts服务)
import ElementResizeDetectorMaker from "element-resize-detector";
Vue.prototype.$erd = ElementResizeDetectorMaker();

// 引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/ruoyi";

// 全局方法挂载
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree

import { download } from '@/utils/request'
Vue.prototype.download = download

import plugins from './plugins'
Vue.use(plugins)

// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
Vue.component('RightToolbar', RightToolbar)
// 分页组件
import Pagination from "@/components/Pagination";
Vue.component('Pagination', Pagination)

import router from '@/router/index.js';

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) },
}).$mount('#app')
