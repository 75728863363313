<template>
    <div class="app-total">
        <total ref="Totals" />
    </div>
</template>

<script>
  import total from '@/components/total.vue';
  import { getSessionId } from '@/api/workbench/index.js'
  export default {
    name: 'App',
    components: {
      total
    },
    data() {
      return {
        queryparams: {
          // user: '运盛监管05',
          // password_md5: 'a806f5026dabadc5cff19211d9f4afa2'
          user: 'sys_admin',
          password_md5:'9f1facad9cd486356e5fee6b6354b82b4eea063b2f231eca219654b05ef9bbe8'
        },
      }
    },
    beforeMount() {
      // 获取浏览器导航值
      this.getSearchValue();
    },
    methods: {
      getSearchValue() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const p1 = params.get('username');
        const p2 = params.get('password_md5');
        if(process.env.NODE_ENV !== 'development') {
            this.queryparams.user = p1;
            this.queryparams.password_md5 = p2;
        }
        this.getId();
      },
      // 获取sessionId
      getId() {
        getSessionId(this.queryparams).then(({ data, code }) => {
          console.log("res", data,code);
          if (data && code == 200) {
            this.$nextTick(() => {
              !!this.$refs.Totals && this.$refs.Totals.init(data, this.queryparams)
            })
          }
        })
      },
    }
  }
  </script>
  
  <style>
  .app-total {
    position: relative;
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
    padding: 30px;
    background: #F8F9FB;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
  }
  </style>
  