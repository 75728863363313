<template>
  <div class="total flex">
    <div class="top-container">
      <div class="nums">
        <div class="line-num">
          <div class="flex line_total">
            <div>线路总计</div>
            <div>5条</div>
          </div>
        </div>
        <div class="alarm-num">
          <div class="flex line_total">
            <div>预警数量</div>
            <div>{{ totals || 0 }}条</div>
          </div>
        </div>
      </div>
      <div class="charts-box flex">
        <div class="columnar" id="maychar"></div>
        <div class="cakeshape" id="mycircle"></div>
      </div>
    </div>
    <div class="map-container">
      <el-select @change="handleChange" v-model="current_occurrence" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.label">
        </el-option>
      </el-select>
      <!-- :zoom="15" -->
          <!-- :center="current_lnglat" -->
      <div class="map-s">
        <el-amap 
          class="map"
          vid="amapContainer"
        >
      </el-amap>
      </div>
      <!-- 三沟鑫都-八甲口 (三沟鑫都) -->
      <div class="alert flex" v-if="current_occurrence == '三沟鑫都矿点'">
        <div class="flex a-left">
          <div class="point bgc-primary"></div>
          <div class="pt-name txt-primary">三沟鑫都-八甲口</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-primary">{{ currentName == '三沟鑫都-八甲口' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
        
      </div>
      <div class="alert flex mt-topbox" v-if="current_occurrence == '三沟鑫都矿点'">
        <div class="flex a-left">
          <div class="point bgc-pink"></div>
          <div class="pt-name txt-pink">三沟鑫都-沁水西</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-pink">{{ currentName == '三沟鑫都-沁水西' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
      </div>
      <!-- 诚南-大富运 -->
      <div class="alert flex" v-if="current_occurrence == '诚南矿点'">
        <div class="flex a-left">
          <div class="point bgc-primary"></div>
          <div class="pt-name txt-primary">诚南-大富运</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-primary">{{ currentName == '诚南-大富运' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
        
      </div>
      <!-- 大富运-八甲口 -->
      <div class="alert flex" v-if="current_occurrence == '大富运矿点'">
        <div class="flex a-left">
          <div class="point bgc-primary"></div>
          <div class="pt-name txt-primary">大富运-八甲口</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-primary">{{ currentName == '大富运-八甲口' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
        
      </div>
      <!-- 固隆 -->
      <div class="alert flex" v-if="current_occurrence == '固隆矿点'">
        <div class="flex a-left">
          <div class="point bgc-primary"></div>
          <div class="pt-name txt-primary">固隆-大富运</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-primary">{{ currentName == '固隆-大富运' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
        
      </div>
      <!-- 润东 -->
      <div class="alert flex" v-if="current_occurrence == '润东矿点'">
        <div class="flex a-left">
          <div class="point bgc-primary"></div>
          <div class="pt-name txt-primary">润东-荣发</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-primary">{{ currentName == '润东-荣发' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
        
      </div>
      <!-- 亿欣 -->
      <div class="alert flex" v-if="current_occurrence == '亿欣矿点'">
        <div class="flex a-left">
          <div class="point bgc-primary"></div>
          <div class="pt-name txt-primary">亿欣-八甲口</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-primary">{{ currentName == '亿欣-八甲口' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
        
      </div>
      <div class="alert flex mt-topbox" v-if="current_occurrence == '亿欣矿点'">
        <div class="flex a-left">
          <div class="point bgc-pink"></div>
          <div class="pt-name txt-pink">亿欣-龙岗</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-pink">{{ currentName == '亿欣-龙岗' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
      </div>
      <div class="alert flex mt-topbox-x" v-if="current_occurrence == '亿欣矿点'">
        <div class="flex a-left">
          <div class="point bgc-green"></div>
          <div class="pt-name txt-green">亿欣-沁水西</div>
        </div>
        <div class="flex a-right" >
          <div class="collect">在途</div>
          <div class="car flex">
            <div class="car_driver txt-green">{{ currentName == '亿欣-沁水西' ? driverCarnum : 0 }}辆</div>
            <!-- <div class="car_total">/0</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, PieChart } from 'echarts/charts';
// 引入标题，提示框，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  PieChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
]);

// 引入地图加载实例方法
import { lazyAMapApiLoaderInstance } from 'vue-amap';
import { getAllCarId, transportTotal, getAlarmTotal } from '@/api/workbench/index.js'
import { useRequest } from 'vue-request';
import "@/plugins/aMap.js";
export default {
  name: "total",
  data() {
    return {
      totals: 0,
      options: [
        {
          value: '01',
          label: '三沟鑫都矿点',
          lnglat: [112.032834435,35.653028705]
        },
        {
          value: '02',
          label: '诚南矿点',
          lnglat: [112.49116503133965, 35.377865628427514]
        },
        {
          value: '03',
          label: '大富运矿点',
          lnglat: [112.4809108139286, 35.53701885967064]
        },
        {
          value: '04',
          label: '固隆矿点',
          lnglat: [112.2634526502018, 35.54435746434592]
        },
        {
          value: '05',
          label: '润东矿点',
          lnglat: [112.544388741, 35.535953331]
        },
        {
          value: '06',
          label: '亿欣矿点',
          lnglat: [112.01581921455002, 35.66097194862746]
        }
      ],
      current_occurrence: '三沟鑫都矿点',
      queryParams: {
        user: '',
        password_md5: ''
      },
      mycharts: null,
      sessionId: '',
      // 车辆的所有坐标点
      carArrs: [],
      // 经纬度数组
      carLngLat: [],
      current_lnglat: [112.032834435,35.653028705],
      map: null,
      myChart_round: null,
      markers: [],
      markerInstances: [], // 标注点实例
      currentName: '', // 当前线路名称
      driverCarnum: 0, // 当前车辆
    }
  },
  watch: {
    current_lnglat(newCenter) {
      if (this.map) {
        // 重新设置中心点
        this.map.setCenter(newCenter)
        // 重新设置层级
        this.map.setZoom(14)
      }
    },
  },
  methods: {
    init(SessionId, Query) {
      this.sessionId = SessionId;
      this.queryParams.user = Query.user;
      this.queryParams.password_md5 = Query.password_md5;
      // 初始化地图
      this.initMap();
      // 获取每日线路发运数量
      this.getsamedayshipment();
      // 获取报警数量
      this.getalarmList();
      this.getCarIds();
      // 每日线路
      this.getCharts();
      // 预警
      this.getroundness();
    },
    // 每日线路发运数量
    getsamedayshipment() {
      useRequest(() => transportTotal(this.queryParams), {
        // 10分钟请求一次
        pollingInterval: 600000,
        pollingWhenHidden: true,
        onSuccess: ({data, code}) => {
          console.log("res发运", data, code);
          if (!!data && code == 200) {
            this.mycharts.setOption({
              xAxis:{
                data: [...Object.keys(data)]
              },
              series: [
                {
                  data: [...Object.values(data)],
                  label: {
                    show: true,
                    position: 'top',
                    formatter: function(data) {
                      return data.value
                    }
                  }
                }
              ]
            })
          }
        }
      })
    },
    getalarmList() {
      useRequest(() => getAlarmTotal(this.queryParams), {
        // 10分钟请求一次
        pollingInterval: 600000,
        pollingWhenHidden: true,
        onSuccess: ({ data, code }) => {
          console.log("baojingres", data, code);
          if (!!data && code == 200) {
            let list = [];
            if (Object.keys(data).length > 0) {
              this.totals = Object.values(data).reduce((account, currentNum) => {
                return account + currentNum
              }, 0)
              for (let i = 0; i < Object.keys(data).length; i++) {
                list.push({ value: Object.values(data)[i], name: Object.keys(data)[i] })
              }
              this.myChart_round.setOption({
                legend: {
                  formatter: function(name) {
                    let tarValue;
                    list.forEach((item) => {
                      if (name == item.name) {
                        tarValue = item.value
                      }
                    })
                    return [`{a|${name}}` + `{b|${tarValue}}`].join("");
                  },
                  
                },
                series: [
                  
                  {
                    label: {
                      normal: {
                        formatter: [
                          '{a|累计总数(条)}',
                          `{b|${this.totals}}`
                        ].join('\n'),
                      }
                    },
                    data: list
                  }
                ]
              })
              
            }
          }
        }
      })
    },
    // 获取当前的carId
    getCarIds() {
      this.carArrs = [];
      useRequest(() => getAllCarId(this.queryParams), {
        // 10分钟请求一次
        pollingInterval: 600000,
        pollingWhenHidden: true,
        onSuccess: ({ data, code }) => {
          console.log("carId", data)
          if (!!data && code == 200) {
            let arr_key = Object.keys(data);
            let arr_val = Object.values(data);
            for(let i = 0; i < arr_key.length; i++) {
              this.carArrs.push({ name: arr_key[i], numbers: arr_val[i] })
            }
          }
        }
      })
    },
    getCharts() {
      // ************引入vue监测插件************* 
      const elementResizeDetectorMaker = require("element-resize-detector");
      let erd = elementResizeDetectorMaker();
      this.mycharts = echarts.init(document.getElementById("maychar"));
      let option = {
        title: {
          text: "当前线路发运数量",
          textStyle: {
            color: "#1C2125",
            fontWeight: "bold",
          },
          left: 20, // 标题距离左边的距离
          top: 22, // 标题距离顶部的距离
        },
        grid: {
          top: 100, // 距离顶部距离
          left: 60,
          bottom: 40,
          right: 15,
          width: "auto",
          height: "auto",
        },
        xAxis: {
          type: "category",
          data: [],
          axisTick: {
            // 轴刻线
            show: false,
          },
          axisLabel: {
            // 轴文字
            color: "#6E7279",
            fontSize: 16,
          },
          axisLine: {
            // 轴线
            show: true,
            lineStyle: {
              color: "#E0E0E0",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            barWidth: "13px",
            itemStyle: {
              color: "#3C85FF",
            },
          },
        ],
      }
      this.mycharts.setOption(option);
      // ***********随着指定元素大小调节图表***********   <-重点在此
      erd.listenTo(document.getElementById("maychar"), () => {
        this.mycharts.resize();
      });

    },
    getroundness() {
      const elementResizeDetectorMaker = require("element-resize-detector");
      let erd = elementResizeDetectorMaker();
      this.myChart_round = echarts.init(document.getElementById("mycircle"));
      let option = {
        title: {
          text: "当前预警统计",
          textStyle: {
            color: "#1C2125",
            fontWeight: "bold",
          },
          left: 20, // 标题距离左边的距离
          top: 22, // 标题距离顶部的距离
        },
        grid: {
          top: 80,
          left: 60
        },
        legend: {
          right: '40',
          top: '125',
          orient: 'vertical',
          itemGap: 20,
          icon: 'circle',
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            color: '#1C2125',
            fontSize: 20,
            lineHeight: 28,
            fontWeight: 400,
            padding: [0,0,0,10],
            rich: {
              a: {
                color: '#787981',
                fontWeight: 500,
                fontSize: 20
              },
              b: {
                color: '#1C2125',
                fontSize: 20,
                fontWeight: 600,
                padding: [0,0,0,5]
              },
            }
          },
        },
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["60%", "50%"],
            center: ["30%", "50%"],
            // 数据为0时不再显示
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: true,
                position: 'center',
                rich: {
                    a: {
                      color: '#787981',
                      fontWeight: 500,
                      fontSize: 20
                    },
                    b: {
                      color: '#1C2125',
                      fontSize: 34,
                      fontWeight: 600,
                    },
                }
              },
              
            },
            color: ['#3885FF', '#57CCFC', '#FF9F18', '#FF5C5F'],
            emphasis: {
              // 高亮时不显示
              disabled: true
              // label: {
              //   show: true,
              // },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      };
      this.myChart_round.setOption(option);

      // ***********随着指定元素大小调节图表***********   <-重点在此
      erd.listenTo(document.getElementById("mycircle"), () => {
        this.myChart_round.resize();
      });
    },
    initMap() {
      lazyAMapApiLoaderInstance.load().then(() => {
        this.map = new AMap.Map("amapContainer", {
          center: new AMap.LngLat(this.current_lnglat[0], this.current_lnglat[1]),
          zoom: 14
        });
        // 点击地图设置
        // this.map.on('click', (e) => {
        //   let marker = new AMap.Marker({
        //     position: [e.lnglat.lng, e.lnglat.lat],
        //     icon: new AMap.Icon({
        //       image: require('@/assets/bill-car.png'),
        //       size: new AMap.Size(65,36)
        //     }),
        //     label: { // 设置标签内容
        //       content: [e.lnglat.lng, e.lnglat.lat],
        //       offset: [0,0],
        //       direction: "top"
        //     }
        //   })
        //   marker.setMap(this.map);
        // })
        this.setMarker();
      })
    },
    setMarker() {
      if (this.markers.length <= 0) {
        return
      }
      this.markers.forEach((item) => {
        let marker = new AMap.Marker({
          position: item.position,
          icon: new AMap.Icon({
            image:require('@/assets/bill-car.png'),
            size: new AMap.Size(65, 36),
            // imageSize: new AMap.Size(84, 36)
          }),
          label: {
            content: item.carplate,
            offset: [0,0],
            direction: 'top'
          }
        });
        // 将标注点添加到地图上
        marker.setMap(this.map);
        // 保存Marker实例
        this.markerInstances.push(marker)
      })
    },
    clearMarkers() {
      if (this.markerInstances.length <= 0) {
        return
      }
      this.markerInstances.forEach(marker => {
        // 移除标注点
        marker.setMap(null);
      });
      this.markerInstances = [];
    },
    handleChange(selectvalue) {
      this.currentName = '';
      this.driverCarnum = '';
      // 清空所有标注点
      this.clearMarkers();
      this.options.forEach(item => {
        if (item.label == selectvalue) {
          this.current_lnglat = item.lnglat;
        }
      })
      let keyword = '矿点';
      let index = selectvalue.indexOf(keyword);
      let result = selectvalue.substring(0, index);
      if (!!this.carArrs && this.carArrs.length > 0) {
        this.carArrs.forEach((item) => {
          if (item.name.includes(result)) {
            this.currentName = item.name;
            this.driverCarnum = item.numbers.length;
            this.getdetails(item.numbers)
          }
        })
      } else {
        // 选中如果有这条线路 并且有车的情况下就去打点
        this.initMap()
      }
      
    },
    getdetails(carList) {
      this.carLngLat = [];
      let list = carList.length > 0 ? carList.join(',') : []
      useRequest(() => getWorksheetLocation({
        session: this.sessionId,
        carIds: list
      }), {
        // 10分钟请求一次
        pollingInterval: 600000,
        pollingWhenHidden: true,
        onSuccess: ({data, code}) => {
          console.log("车辆经纬度", JSON.parse(data))
          if (!!data && code == 200) {
            let belongList = JSON.parse(data).list;
            // glng glat 高德经纬度
            this.carLngLat = belongList;
            let markersList = [];
            belongList.forEach((item) => {
              // 加入的是高德的经纬度和车牌号
              markersList.push({ position: [Number(item.glng), Number(item.glat)], carplate: item.carPlate })
            })
            this.markers = markersList;
            this.initMap()
          }
        }
      })
    }
  },
};
</script>

<style>
.flex {
  display: flex;
}

.total {
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.icon {
  margin: 8px 9px 7px 24px;
  width: 22px;
  height: 22px;
  /* background: blue; */
  background-image: url("~@/assets/slice.png"); /* 使用相对路径 */
  background-size: 100% 100%;
}

.desc {
  margin: 6px 0;
  height: 25px;
  font-weight: 500;
  font-size: 18px;
  color: #1c2125;
  line-height: 25px;
}

.nums {
  display: flex;
}

.line-num,
.alarm-num {
  width: 210px;
  border-radius: 10px;
}

.line-num {
  background: #0070ff;
}

.line_total {
  justify-content: space-between;
  margin: 18px 23px;
}

.line_total div {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}

.alarm-num {
  background: #ff5c5f;
  margin-left: 20px;
}

.charts-box {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.columnar {
  width: calc(100% - 605px);
  height: 420px;
  background: #fff;
  border-radius: 15px;
}

.cakeshape {
  margin-left: 30px;
  width: 575px;
  height: 420px;
  /* background: red; */
  background: #fff;
  border-radius: 15px;
}

.map-container {
  position: relative;
  flex: 1;
  margin-top: 30px;
  /* background: cornflowerblue; */
  /* min-height: 510px; */
}

.occurrence {
  font-weight: 500;
  font-size: 20px;
  color: #1c2125;
}

.map {
  width: 100%;
  height: 510px !important;
  padding: 5px;
  min-height: 510px;
  margin-top: 12px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #0070ff;
}


.alert {
  position: absolute;
  box-sizing: border-box;
  padding: 20px 38px 20px 13px;
  top: 60px;
  left: 20px;
  z-index: 999;
  width: 395px;
  height: 66px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(136,156,196,0.31);
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}



.a-left {

}

.point {
  width: 10px;
  height: 10px;
  
  border-radius: 50%;
  margin-top: 7px;
}

/* 蓝色 */
.txt-primary {
  color: #3c85FF;
}

.bgc-primary {
  background: #3c85FF;
}

/* 粉色 */
.txt-pink {
  color: #FC7D70;
}

.bgc-pink {
  background: #FC7D70;
}

/* 绿色 */
.txt-green {
  color: #50D3BA;
}

.bgc-green {
  background: #50D3BA;
}

/* 距离顶部 */
.mt-topbox {
  top: 136px;
}

.mt-topbox-x {
  top: 212px;
}

.pt-name {
  /* color: #3c85FF; */
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
}

.collect {
  font-size: 18px;
  font-weight: 500;
  color: #1c2125;
  margin-right: 16px;
}

.car {
  font-size: 18px;
  font-weight: 600;
}

.car_total {
  color: #A9A9A9;
}
</style>