import request from '@/utils/request'

// 查询运输监管工作单列表
export function listWorksheet(query) {
  return request({
    url: '/system/worksheet/list',
    method: 'get',
    params: query
  })
}

// 查询运输监管工作单详细
export function getWorksheet(id) {
  return request({
    url: '/system/worksheet/' + id,
    method: 'get'
  })
}

// 删除运输监管工作单
export function delWorksheet(id) {
  return request({
    url: '/system/worksheet/' + id,
    method: 'delete'
  })
}

// 结束运输监管工作单
export function endWorksheet(id) {
  return request({
    url: '/system/worksheet/end/' + id,
    method: 'get'
  })
}
